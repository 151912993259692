import {AccumulativeShadows, RandomizedLight} from "@react-three/drei";
import {useRef} from "react";

export default function Backdrop() {
    const shadows = useRef();

    return (
        <AccumulativeShadows
            ref={shadows}
            temporal
            frames={60}
            alphaTest={0.85}
            scale={10}
            rotation={[Math.PI / 2, 0, 0]}
            position={[0, 0, -0.14]}
        >
            <RandomizedLight amount={4}
                             intensity={0.55}
                             ambient={0.25}
                             radius={9}
                             position={[5, 5, -10]}
            />
        </AccumulativeShadows>
    )
}