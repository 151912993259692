import React, {useMemo} from 'react';
import { Decal, useGLTF, useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { easing } from 'maath';
import {ClampToEdgeWrapping, LinearMipMapLinearFilter, NearestFilter} from "three";

export default function Shirt({color, file}) {
    const { nodes, materials } = useGLTF('/shirt_baked.glb');

    const fullTexture = useTexture(file ?? '/logo192.png');
    fullTexture.wrapS = ClampToEdgeWrapping;
    fullTexture.wrapT = ClampToEdgeWrapping;

    useMemo(() => {

    }, []);
    useFrame((state, delta) => {
        easing.dampC(materials.lambert1.color, color, 0.25, delta);
    });

    return (
        <group>
            <mesh
                castShadow
                geometry={nodes.T_Shirt_male.geometry}
                material={materials.lambert1}
                material-roughness={1}
                dispose={null}
            >
                {/*<planeGeometry args={[1,1]}/>*/}
                <Decal
                    position={[0, 0.04, 0.15]}
                    rotation={[0, 0, 0]}
                    // scale={[0.2,0.15, 0.1]}
                    scale={[0.2,0.2, 0.2]}
                    map={fullTexture}
                    // mapAnisotropy={16}
                    depthTest={false}
                    depthWrite={true}
                />
            </mesh>
        </group>
    );
}
