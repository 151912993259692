import './App.css';
import Customizer from "./components/Customizer";

function App() {
  return (
      <div className="App">
          <Customizer/>
      </div>
  );
}

export default App;
