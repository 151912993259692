import {useRef, useState} from "react";
import { easing } from 'maath';
import {useFrame} from "@react-three/fiber";
export default function CameraRig({children}){
    const group = useRef();

    const [isDragging, setDragging] = useState(false);

    const handlePointerDown = () => {
        setDragging(true);
    };

    const handlePointerUp = () => {
        setDragging(false);
    };

    useFrame((state, delta) => {
        let targetPosition = [0, 0, 2.5];

        easing.damp3(state.camera.position, targetPosition, 0.25, delta)

        // easing.dampE(
        //     group.current.rotation,
        //     [state.pointer.y / 2, -state.pointer.x / 2, 0],
        //     0.25,
        //     delta
        // )


        if (isDragging) {
            easing.dampE(
                group.current.rotation,
                [state.pointer.y / 2, -state.pointer.x / 2, 0],
                0.25,
                delta
            );
        }
    });

    return (
        <group ref={group}
               onPointerDown={handlePointerDown}
               onPointerUp={handlePointerUp}
        >
            {children}
        </group>
    )
}