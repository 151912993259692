import {Canvas} from "@react-three/fiber";
import CameraRig from "./CameraRig";
import Shirt from "./Shirt";
import Backdrop from "./Backdrop";
import {useRef, useState} from "react";
import {Environment} from "@react-three/drei";

export default function Customizer() {
    const [color, setColor] = useState('dodgerblue');
    const [file, setFile] = useState(null);

    const input = useRef();

    function selectFile() {
        input.current.click();
    }

    function fileToDataURL(file, callback) {
        const reader = new FileReader();

        // Set up a callback function to handle the result
        reader.onloadend = function () {
            callback(reader.result);
        };

        // reader.onload = function (e) {
        //     const img = new Image();
        //
        //     img.onload = function () {
        //         const aspectRatio = img.width / img.height;
        //         console.log(img);
        //         console.log(img.width);
        //         console.log(img.height);
        //         // aspectRatioDisplay.textContent = `Aspect Ratio: ${aspectRatio.toFixed(2)}`;
        //     };
        //
        //     // img.src = e.target.result;
        // };

        // Read the file as a data URL
        reader.readAsDataURL(file);
    }

    function handleFileChange(event) {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            fileToDataURL(selectedFile, function (dataURL) {
                const image = new Image();
                image.src = dataURL;
                setFile(dataURL);
            });
        }
    }

    return (
        <div className="flex">
            <div className="w-full max-w-full transition-all ease-in min-h-screen cursor-pointer relative">
                <Canvas
                    camera={{position: [0, 0, 0], fov: 25}}
                    className="w-full max-w-full h-full transition-all ease-in"
                >
                    <ambientLight/>
                    <Environment preset="city"/>
                    <CameraRig>
                        {/*<Backdrop/>*/}
                        <Shirt color={color} file={file}/>
                    </CameraRig>
                </Canvas>
                <div className="absolute bottom-24 left-0 right-0 flex items-center justify-center">
                    <p className="text-white bg-gray-800 px-8 py-4 rounded-md ">Use mouse to rotate</p>
                </div>
            </div>
            <div>
                <div className="w-[400px]">
                    <div className="w-[400px] px-8 py-8 border-l min-h-screen overflow-y-scroll transition-all">
                        <p className="mb-1">Article no: 12915234</p>
                        <h2 className="font-bold text-2xl">Modern T-Shirt</h2>
                        <p className="mt-4">A sleek T-Shirt for both men and women with a touch of modern fashion.</p>

                        <div className="mt-10">
                            <p className="mb-2">Colors</p>
                            <div className="flex gap-x-4">
                                <button className="h-[24px] w-[24px] rounded-full bg-[dodgerblue]"
                                        onClick={() => setColor('dodgerblue')}></button>
                                <button className="h-[24px] w-[24px] rounded-full bg-red-500"
                                        onClick={() => setColor('red')}></button>
                                <button className="h-[24px] w-[24px] rounded-full bg-yellow-500"
                                        onClick={() => setColor('yellow')}></button>
                                <button className="h-[24px] w-[24px] rounded-full bg-green-500"
                                        onClick={() => setColor('green')}></button>
                                <button className="h-[24px] w-[24px] rounded-full bg-purple-500"
                                        onClick={() => setColor('purple')}></button>
                                <button className="h-[24px] w-[24px] rounded-full bg-pink-500"
                                        onClick={() => setColor('pink')}></button>
                            </div>
                        </div>


                        <div className="mt-10">
                            <input className="hidden" type="file" ref={input} onChange={handleFileChange}/>
                            <p className="mb-2 text-gray-600">Upload logo</p>
                            <button className="border h-[120px] flex items-center w-full justify-center"
                                    onClick={selectFile}>
                                {file ? <img src={file} alt=""
                                             className="h-[100px] w-auto object-contain mx-auto"/>

                                    : <p>Click to upload</p>}
                            </button>

                        </div>

                        {/*<div className="mt-10">*/}
                        {/*    <input className="hidden" type="file" ref={input} onChange={handleFileChange}/>*/}
                        {/*    <p className="mb-4">Upload full print</p>*/}
                        {/*    <button className="border h-[120px] flex items-center w-full justify-center"*/}
                        {/*            onClick={selectFile}>*/}
                        {/*        Click to upload*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}